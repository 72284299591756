import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TextComponent } from '../components/text/text.component'
import { LazyLoadingDirective } from './lazy-loading.directive'
import { ResourcesModule } from '../resources'
import { SwipeDirective } from './swipe.directive'

@NgModule({
  imports: [CommonModule,
    SwipeDirective,
    RouterModule,
    LazyLoadingDirective,
    ResourcesModule,
    TextComponent], providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class CoreModule { }
